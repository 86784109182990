@import url('https://fonts.googleapis.com/css?family=Victor+Mono&display=swap');

.App {
  text-align: center;
  scrollbar-width: none;
  overflow: hidden;
  user-select: none;
  font-family: 'Victor Mono';
  font-weight: 300;
}

::-webkit-scrollbar {
  display: none;
}

.App-link {
  color: #FF00FF;
}

.expanding-div {
  width: 200px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* CSS rule that applies to text that I want the user to be able to select. This will be identified by the class "selectable" */
.selectable {
  user-select: all;
}

/* CSS rule that applies to a class called "retro" that makes anything with that class styled like windows 95 including retro styled font */
.retro {
  background-color: #008080;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 15px;
  margin: 5px;
  font-family: 'Courier New', Courier, monospace;
}